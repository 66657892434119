import {
  mdiAccountOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'
import { getFieldsAllByFacility } from '@api'
import { isObjEmpty } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import usePermissions from '@core/utils/usePermissions'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@core/utils/useFilters'

export default function useFieldsList() {
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { configOrganization, configFacility } = useSelectOptions()
  const { updateFilter, getFilterByModule } = useFilters()
  const fieldListTable = ref([])

  const tableColumns = computed(() => [
    { text: 'ID', value: 'id', show: true },
    { text: t('fields.name').toUpperCase(), value: 'name', show: true },
    { text: t('facilities.facility').toUpperCase(), value: 'facility.name', show: true },
    { text: t('sports.sport').toUpperCase(), value: 'sport.name', show: true },
    { text: t('cameras.camera').toUpperCase(), value: 'camera.name', show: true },
    { text: t('fields.h_pricing').toUpperCase(), value: 'hourly_pricing', show: true },
    { text: t('fields.recording_type').toUpperCase(), value: 'recording_type_str', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(44, 46, 47),
    },
  ])

  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const searchQueryF = ref('')
  const totalFieldListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const fieldTotalLocal = ref([])

  const filterFields = field => {
    if (searchQueryF.value && typeof (searchQueryF.value) === 'string') {
      if (field.name.toLowerCase().includes(searchQueryF.value)
        || (field.facility && field.facility.name && field.facility.name.toLowerCase().includes(searchQueryF.value))
        || (field.sport && field.sport.name && field.sport.name.toLowerCase().includes(searchQueryF.value))
        || (field.camera && field.camera.name && field.camera.name.toLowerCase().includes(searchQueryF.value))
        || field.hourly_pricing.toString().toLowerCase().includes(searchQueryF.value)
        || field.recording_type_str.toLowerCase().includes(searchQueryF.value)
        || field.status_str.toLowerCase().includes(searchQueryF.value)) {
        return true
      }

      return false
    }

    return true
  }

  const fetchFields = async () => {
    const response = await getFieldsAllByFacility(configFacility.value || 0)

    const filterOption = {}
    filters.value = null
    if (searchQueryF.value) filterOption.search = searchQueryF.value
    filters.value = filterOption
    const filteredData = response.data.filter(filterFields)

    fieldListTable.value = filteredData
    totalFieldListTable.value = filteredData.length
    fieldTotalLocal.value = null
    loading.value = false
    flag.value = true
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQueryF.value = value.search
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchFields()
    }, 1000)
  }

  watch([searchQueryF, configOrganization, configFacility], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    updateFilter('views-field-list', 'search', searchQueryF.value)
    await getDataBySearch()
  })

  watch([configFacility], async () => {
    searchQueryF.value = ''
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await getDataBySearch()
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveFieldRoleVariant = role => {
    if (role === 'subscriber' || role === 'F') return 'info'
    if (role === 'author' || role === 'R') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin' || role === 'A') return 'error'

    return 'primary'
  }

  const resolveFieldRoleIcon = role => {
    if (role === 'subscriber' || role === 'F') return mdiAccountOutline
    if (role === 'author' || role === 'R') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin' || role === 'A') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveFieldStatusVariant = status => {
    if (status === 'pending' || status === 'S') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'
    if (status === 'T') return 'info'

    return 'primary'
  }

  const resolveFieldRoleText = role => {
    if (role === 'subscriber' || role === 'F') return 'Facility'
    if (role === 'author' || role === 'R') return 'Representative'
    if (role === 'maintainer') return 'Maintainer'
    if (role === 'editor') return 'Editor'
    if (role === 'admin' || role === 'A') return 'Administrator'

    return 'Administrator'
  }

  const resolveFieldStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  return {
    fieldListTable,
    computedTableColumns,
    tableColumns,
    searchQueryF,
    totalFieldListTable,
    loading,
    options,
    filters,
    fieldTotalLocal,
    configOrganization,
    configFacility,
    t,
    fetchFields,
    resolveFieldRoleVariant,
    resolveFieldRoleIcon,
    resolveFieldStatusVariant,
    resolveFieldStatusText,
    resolveFieldRoleText,
    hasPermission,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
