<template>
  <div id="field-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t("fields.fields") }}
      </v-card-title>

      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-col
          cols="12"
          sm="3"
          class="py-0"
        >
          <v-text-field
            v-model="searchQueryF"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-fab-transition v-if="hasPermission(45)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              absolute
              top
              right
              fab
              class="mb-4 me-3"
              v-on="on"
              @click="onCreate"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t("tooltip.new") }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card> -->

    <v-card>
      <!-- <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title> -->

      <!-- <v-divider /> -->

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQueryF"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(45)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="fieldListTable"
        item-key="id"
        :options.sync="options"
        :loading="loading"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page')}"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.name ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <span
                class="font-weight-medium"
              >{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'views-field-form', params: { id: item.id, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveFieldRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveFieldRoleVariant(item.role)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveFieldRoleVariant(item.role)"
              >
                {{ resolveFieldRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ resolveFieldRoleText(item.role) }}</span>
          </div>
        </template>

        <!-- hourly pricing -->
        <template #[`item.hourly_pricing`]="{item}">
          {{ formatCurrency(item.hourly_pricing) }}
        </template>

        <template #[`item.facility.name`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.facility.name }}</span>
        </template>

        <!-- recording_type -->
        <template #[`item.recording_type_str`]="{item}">
          <v-chip
            small
            :color="resolveFieldStatusVariant(item.recording_type)"
            :class="`${resolveFieldStatusVariant(item.recording_type)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <!-- {{ resolveFieldStatusText(item.status) }} -->
            {{ item.recording_type_str }}
          </v-chip>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveFieldStatusVariant(item.status)"
            :class="`${resolveFieldStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <!-- {{ resolveFieldStatusText(item.status) }} -->
            {{ item.status_str }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(44)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>Details</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(46)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="success"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onEdit(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(47)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onDelete(item.id)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiEyeOutline } from '@mdi/js'
import { onMounted } from '@vue/composition-api'
import { useRouter, formatCurrency } from '@core/utils'
import { deleteField } from '@api'

import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import useFieldsList from './useFieldsList'

export default {
  setup() {
    const { router, route } = useRouter()

    const {
      fieldListTable,
      computedTableColumns,
      tableColumns,
      totalFieldListTable,
      loading,
      options,
      filters,
      searchQueryF,

      t,
      fetchFields,
      resolveFieldRoleVariant,
      resolveFieldRoleIcon,
      resolveFieldStatusVariant,
      resolveFieldStatusText,
      resolveFieldRoleText,
      hasPermission,
      setFilters,
      setOptions,
      getFilterByModule,
    } = useFieldsList()

    const onCreate = () => {
      router.push({
        name: 'views-field-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onEdit = item => {
      router.push({
        name: 'views-field-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 3,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onShow = item => {
      router.push({
        name: 'views-field-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deleteField(id)
          if (response.ok) {
            success(response.message)
            fetchFields()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const filtersStored = getFilterByModule('views-field-list')
      if (filtersStored.search) searchQueryF.value = filtersStored.search

      await fetchFields()
    })

    return {
      fieldListTable,
      computedTableColumns,
      tableColumns,
      totalFieldListTable,
      loading,
      options,
      filters,
      searchQueryF,

      onCreate,
      onEdit,
      onShow,
      onDelete,
      avatarText,
      resolveFieldRoleVariant,
      resolveFieldRoleIcon,
      resolveFieldStatusVariant,
      resolveFieldStatusText,
      resolveFieldRoleText,
      fetchFields,
      hasPermission,
      setFilters,
      setOptions,
      formatCurrency,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
