var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"field-list"}},[_c('v-card',[_c('v-row',{staticClass:"px-2 ma-0 text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"placeholder":_vm.t('search'),"outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQueryF),callback:function ($$v) {_vm.searchQueryF=$$v},expression:"searchQueryF"}})],1),_c('v-spacer'),(_vm.hasPermission(45))?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"color":"secondary","block":""},on:{"click":_vm.onCreate}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.t('tooltip.add'))+" ")],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.computedTableColumns,"items":_vm.fieldListTable,"item-key":"id","options":_vm.options,"loading":_vm.loading,"loading-text":_vm.t('table.loading_text'),"no-data-text":_vm.t('table.no_data'),"header-props":{sortByText: _vm.t('table.sort_by')},"footer-props":{itemsPerPageText: _vm.t('table.item_page')}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.name ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":"primary","size":"32"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'views-field-form', params: { id: item.id, dataParams: item, option: 3 } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveFieldRoleVariant(item.role)) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveFieldRoleVariant(item.role)}},[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveFieldRoleVariant(item.role)}},[_vm._v(" "+_vm._s(_vm.resolveFieldRoleIcon(item.role))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.resolveFieldRoleText(item.role)))])],1)]}},{key:"item.hourly_pricing",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.hourly_pricing))+" ")]}},{key:"item.facility.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.facility.name))])]}},{key:"item.recording_type_str",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveFieldStatusVariant(item.recording_type)) + "--text"),attrs:{"small":"","color":_vm.resolveFieldStatusVariant(item.recording_type)}},[_vm._v(" "+_vm._s(item.recording_type_str)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveFieldStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveFieldStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status_str)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(44))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"info"},on:{"click":function($event){return _vm.onShow(item)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Details")])]):_vm._e(),(_vm.hasPermission(46))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"success"},on:{"click":function($event){return _vm.onEdit(item)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.hasPermission(47))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"error"},on:{"click":function($event){return _vm.onDelete(item.id)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }