import { ref, watch, computed } from '@vue/composition-api'
import { getCamerasByFacility, getCameras } from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@core/utils/useFilters'
import useCryptoJs from '@core/utils/useCryptoJs'

export default function useCamerasList() {
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { configFacility } = useSelectOptions()
  const { updateFilter, getFilterByModule } = useFilters()
  const { userData } = useCryptoJs()

  const cameraListTable = ref([])
  const tableColumns = computed(() => [
    { text: t('cameras.name').toUpperCase(), value: 'name', show: true },
    { text: t('cameras.mac_address').toUpperCase(), value: 'mac_address', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(27, 29, 30),
    },
  ])

  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const searchQuery = ref('')
  const totalCameraListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['field.facility.name'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const descSort = ref([])
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)

  // fetch data
  const fetchCameras = async () => {
    try {
      loading.value = true
      let response = null
      if (userData.value.role !== 'A') response = await getCamerasByFacility(configFacility.value || 0)
      else response = await getCameras()

      const filterOption = {}
      filters.value = null
      if (searchQuery.value) filterOption.search = searchQuery.value
      filters.value = filterOption

      const filteredData = response.data.filter(
        camera =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (camera.field && camera.field.name.toLowerCase().includes(searchQuery.value)) ||
          (camera.field && camera.field.facility.name.toLowerCase().includes(searchQuery.value)) ||
          camera.name.toLowerCase().includes(searchQuery.value) ||
          camera.mac_address.toLowerCase().includes(searchQuery.value) ||
          camera.status_str.toLowerCase().includes(searchQuery.value),
      )

      cameraListTable.value = filteredData
      totalCameraListTable.value = filteredData.length
      loading.value = false
      flag.value = true
    } catch (e) {
      console.error(e)
      cameraListTable.value = []
      totalCameraListTable.value = 0
      loading.value = false
      flag.value = true
    }
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchCameras()
    }, 1000)
  }

  watch([configFacility], async () => {
    searchQuery.value = ''
  })

  watch([configFacility], async () => {
    if (userData.value.role !== 'A') {
      flag.value = false
      if (!hasOptionsFilter.value) options.value.page = 1
      loading.value = true
      await getDataBySearch()
    }
  })

  watch([searchQuery], async () => {
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    loading.value = true
    updateFilter('views-camera-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchCameras()
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCameraStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  return {
    cameraListTable,
    tableColumns,
    computedTableColumns,
    searchQuery,
    totalCameraListTable,
    loading,
    options,
    hasOptionsFilter,
    flag,
    filters,
    descSort,

    t,
    fetchCameras,
    resolveCameraStatusVariant,
    hasPermission,
    setOptions,
    setFilters,
    updateFilter,
    getFilterByModule,
  }
}
